const pcHome = () => import('@/views/pc/home/index.vue'); //首页
const pcSheHu = () => import('@/views/pc/sheHu/index.vue'); //奢护
const pcShanShi = () => import('@/views/pc/shanShi/index.vue'); //膳食
const pcPatent = () => import('@/views/pc/patent/index.vue'); //专利
const pcBrandStory = () => import('@/views/pc/brandStory/index.vue'); //品牌故事
const pcBrandInfo = () => import('@/views/pc/brandInfo/index.vue'); //品牌资讯
const pcBrandInfoInnovate = () => import('@/views/pc/brandInfoInnovate/index.vue'); //品牌资讯-研发创新
const pcBrandInfoSociety = () => import('@/views/pc/brandInfoSociety/index.vue'); //品牌资讯-社会动态
const pcBrandIntroduce = () => import('@/views/pc/brandIntroduce/index.vue'); //品牌资讯-品牌介绍
const pcBrandInfoAboutUs = () => import('@/views/pc/brandInfoAboutUs/index.vue'); //品牌资讯-关于我们
const pcSecurity = () => import('@/views/pc/security/index.vue'); //防伪鉴别
const pcJinghuayeDetail = () => import('@/views/pc/jinghuayeDetail/index.vue'); //精华液详情
const pcJinghuashuangDetail = () => import('@/views/pc/jinghuashuangDetail/index.vue'); //精华霜详情
const pcGegendanbaiDetail = () => import('@/views/pc/gegendanbaiDetail/index.vue'); //葛根蛋白燕窝饮品详情
const pcShanshiYanwoZhiwuDetail = () => import('@/views/pc/shanshiYanwoZhiwuDetail/index.vue'); //膳食-燕窝胶原多肽植物饮详情
const pcSuoxianyanwoDetail = () => import('@/views/pc/suoxianyanwoDetail/index.vue'); //锁鲜燕窝详情
const pcGegenjinzhimeixiongtieDetail = () => import('@/views/pc/gegenjinzhimeixiongtieDetail/index.vue'); //山荷集葛根紧致美胸贴详情
const pcHuayueqingmeirutieDetail = () => import('@/views/pc/huayueqingmeirutieDetail/index.vue'); //特贴-花月情美乳贴详情
const pcXiongbuhuanhuojinghuayouDetail = () => import('@/views/pc/xiongbuhuanhuojinghuayouDetail/index.vue'); //胸部焕活精华油详情
const pcJinghuayoupenwuDetail = () => import('@/views/pc/jinghuayoupenwuDetail/index.vue'); //野葛根胸部精华喷雾详情
const pcJinHuaYeMeiRuDetail = () => import('@/views/pc/jinhuaYeMeiRuDetail/index.vue'); //柔嫩美乳精华液
const pcYuTingFenGuTiYinLiaoDetail = () => import('@/views/pc/yuTingFenGuTiYinLiaoDetail/index.vue'); // 山荷集野葛根双参玉亭粉固体饮料
export default [
  {
    path: '/pc/home',
    component: pcHome,
    name: 'pcHome'
  },
  {
    path: '/pc/shehu',
    component: pcSheHu,
    name: 'pcSheHu'
  },
  {
    path: '/pc/shanshi',
    component: pcShanShi,
    name: 'pcShanShi'
  },
  {
    path: '/pc/pcPatent',
    component: pcPatent,
    name: 'pcPatent'
  },
  {
    path: '/pc/pcBrandStory',
    component: pcBrandStory,
    name: 'pcBrandStory'
  },
  {
    path: '/pc/pcBrandInfo',
    component: pcBrandInfo,
    name: 'pcBrandInfo'
  },
  {
    path: '/pc/pcBrandInfoInnovate',
    component: pcBrandInfoInnovate,
    name: 'pcBrandInfoInnovate'
  },
  {
    path: '/pc/pcBrandInfoSociety',
    component: pcBrandInfoSociety,
    name: 'pcBrandInfoSociety'
  },
  {
    path: '/pc/brandIntroduce',
    component: pcBrandIntroduce,
    name: 'pcBrandIntroduce'
  },
  {
    path: '/pc/brandInfoAboutUs',
    component: pcBrandInfoAboutUs,
    name: 'pcBrandInfoAboutUs'
  },
  {
    path: '/pc/pcSecurity',
    component: pcSecurity,
    name: 'pcSecurity'
  },
  {
    path: '/pc/pcJinghuayeDetail',
    component: pcJinghuayeDetail,
    name: 'pcJinghuayeDetail'
  },
  {
    path: '/pc/pcJinghuashuangDetail',
    component: pcJinghuashuangDetail,
    name: 'pcJinghuashuangDetail'
  },
  {
    path: '/pc/pcGegendanbaiDetail',
    component: pcGegendanbaiDetail,
    name: 'pcGegendanbaiDetail'
  },
  {
    path: '/pc/pcShanshiYanwoZhiwuDetail',
    component: pcShanshiYanwoZhiwuDetail,
    name: 'pcShanshiYanwoZhiwuDetail'
  },
  {
    path: '/pc/pcSuoxianyanwoDetail',
    component: pcSuoxianyanwoDetail,
    name: 'pcSuoxianyanwoDetail'
  },
  {
    path: '/pc/pcGegenjinzhimeixiongtieDetail',
    component: pcGegenjinzhimeixiongtieDetail,
    name: 'pcGegenjinzhimeixiongtieDetail'
  },
  {
    path: '/pc/pcHuayueqingmeirutieDetail',
    component: pcHuayueqingmeirutieDetail,
    name: 'pcHuayueqingmeirutieDetail'
  },
  {
    path: '/pc/pcXiongbuhuanhuojinghuayouDetail',
    component: pcXiongbuhuanhuojinghuayouDetail,
    name: 'pcXiongbuhuanhuojinghuayouDetail'
  },
  {
    path: '/pc/pcJinghuayoupenwuDetail',
    component: pcJinghuayoupenwuDetail,
    name: 'pcJinghuayoupenwuDetail'
  },
  {
    path: '/pc/pcJinHuaYeMeiRuDetail',
    component: pcJinHuaYeMeiRuDetail,
    name: 'jinhuaYeMeiRuDetail'
  },
  {
    path: '/pc/pcYuTingFenGuTiYinLiaoDetail',
    component: pcYuTingFenGuTiYinLiaoDetail,
    name: 'yuTingFenGuTiYinLiaoDetail'
  }
];
